import { ColorTokens } from "design-system";
import { IconButton } from "Everlaw/UI/Button";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import * as React from "react";
import { FC, ReactNode } from "react";
import { Link } from "design-system";
import Dom = require("Everlaw/Dom");
import FloatingPanel = require("Everlaw/UI/FloatingPanel");
import Is = require("Everlaw/Core/Is");
import Util = require("Everlaw/Util");
import Organization = require("Everlaw/Organization");
import Partner = require("Everlaw/Partner");
import Str = require("Everlaw/Core/Str");
import { CreateBuyNewCaseForm } from "Everlaw/MarketoForm";
import * as moment from "moment-timezone";
export var supportPhone = JSP_PARAMS.Help.supportPhone;
export var supportEmail = JSP_PARAMS.Help.supportEmail;
export var trainingLink = JSP_PARAMS.Help.trainingLink;
export const inHouseSupportTimezoneId = JSP_PARAMS.Help.supportTimezoneId;
export const inHouseSupportHours = JSP_PARAMS.Help.supportHours;
// If both weekend support hours are set to -1, there's no weekend support for this VPC
export const hasInHouseSaturdaySupport =
    inHouseSupportHours.saturday.open !== -1 && inHouseSupportHours.saturday.close !== -1;
export const hasInHouseSundaySupport =
    inHouseSupportHours.sunday.open !== -1 && inHouseSupportHours.sunday.close !== -1;
export const inHouseSupportMessage = "Need help? We're here for you!";
export const CLOSED_STRING = "Closed";
export const TERMS_OF_USE_LINK = "https://www.everlaw.com/terms-of-use/";
export const PRIVACY_NOTICE_LINK = "https://www.everlaw.com/privacy-notice/";
export const CALIFORNIA_PRIVACY_LINK =
    "https://www.everlaw.com/privacy-notice/#california-resident-privacy-rights";

export let pageName: string = JSP_PARAMS.Help.pageName;
export function setHelpPageName(name: string) {
    pageName = name;
}

export var viewAllPage = JSP_PARAMS.Help.helpPage;
export function setViewAllPage(page: string) {
    viewAllPage = page;
}

export function addHelp(parent: Dom.Nodeable, leadinPrompt: string) {
    const emailLink = makeEmailLink("everblue-link-bold");
    const content: Dom.Content = [Dom.div(leadinPrompt), emailLink];
    if (!Str.isNullOrWhitespace(supportPhone)) {
        content.push(" or call us at ", supportPhone);
    }
    Dom.create(
        "div",
        {
            class: "help-support-details",
            content,
        },
        parent,
    );
}

interface EmailLinkProps {
    className?: string;
    supportEmail?: string;
    children?: ReactNode;
}

export const EmailLink: FC<EmailLinkProps> = ({
    className,
    supportEmail = JSP_PARAMS.Help.supportEmail,
    children = `Email ${supportEmail}`,
}) => {
    return (
        <Link
            href={`mailto:${supportEmail}`}
            className={className}
            newTab={true}
            onClick={() => ga_event("Help", "Send Email")}
        >
            {children}
        </Link>
    );
};

export function makeEmailLink(
    textClass: string,
    supportEmail: string = JSP_PARAMS.Help.supportEmail,
    emailUs = true,
) {
    return Dom.create("a", {
        href: "mailto:" + supportEmail,
        class: textClass,
        target: "_blank",
        rel: "noopener noreferrer",
        onclick: function () {
            ga_event("Help", "Send Email");
        },
        content: "Email " + (emailUs ? "us" : supportEmail),
    });
}

export function makeOpenNewCaseForm(textClass: string) {
    // A button that opens a modal dialog.
    return wrapReactComponent(CreateBuyNewCaseForm, {
        buttonStyle: textClass,
        isHelpPage: true,
    }).getNode();
}
export const learnMoreLinks = {
    Assignments:
        "https://support.everlaw.com/hc/en-us/articles/210222443-Introduction-to-Assignments",
    Search: "https://support.everlaw.com/hc/en-us/articles/205466645-Search-Overview-and-Examples",
    Binders: "https://support.everlaw.com/hc/en-us/articles/209619346-Binders",
    Chronology:
        "https://support.everlaw.com/hc/en-us/articles/206439403-Introduction-to-StoryBuilder",
    LanguageTools:
        "https://support.everlaw.com/hc/en-us/articles/360024408132-General-Settings#h_c1387724-76c9-416e-b8a9-3b1e205d305b",
};

// Assumes hour is an integer between 0 and 24
export function formatHour(hour: number, minute: number) {
    const minuteString = minute === 0 ? "" : (minute < 10 ? ":0" : ":") + minute;
    if (hour <= 0 || hour === 24) {
        return "12" + minuteString + " am";
    }
    if (hour === 12) {
        return "12" + minuteString + " pm";
    }
    if (hour > 24) {
        return formatHour(hour % 24, minute);
    }
    if (hour > 12) {
        return hour - 12 + minuteString + " pm";
    }
    return hour + minuteString + " am";
}

/**
 * This function will truncate to the hour (e.g. 4:30 pm == 4 pm), assumes that the opening
 * hour is am and the closing hour is pm, and the opening and closing hours are divided
 * by a "-" (e.g. "8 am -5 pm").
 */
export function parseSupportHours(partnerSupportHours: String) {
    if (!partnerSupportHours) {
        return null;
    }
    const tokens = partnerSupportHours.split("-");
    // Return if hours are not in expected format (eg 8am - 8pm)
    if (tokens.length !== 2) {
        return null;
    }

    const openSplit = tokens[0].split(":");
    let hourOpen = openSplit.length > 0 && Util.toInt(openSplit[0].replace(/[^0-9]+/g, ""));
    let minuteOpen = openSplit.length > 1 && Util.toInt(openSplit[1].replace(/[^0-9]+/g, ""));
    const closedSplit = tokens[1].split(":");
    let hourClosed = closedSplit.length > 0 && Util.toInt(closedSplit[0].replace(/[^0-9]+/g, ""));
    let minuteClosed = closedSplit.length > 1 && Util.toInt(closedSplit[1].replace(/[^0-9]+/g, ""));

    if (!hourOpen || !hourClosed) {
        return null;
    }

    if (hourOpen < 12 && tokens[0].toLowerCase().indexOf("pm") !== -1) {
        hourOpen += 12;
    }

    if (!minuteOpen || minuteOpen > 59) {
        minuteOpen = 0;
    }

    if (!minuteClosed || minuteClosed > 59) {
        minuteClosed = 0;
    }

    // convert to 24-hour time
    if (hourClosed < 12 && tokens[1].toLowerCase().indexOf("pm") !== -1) {
        hourClosed += 12;
    }
    return { open: hourOpen, openMin: minuteOpen, close: hourClosed, closeMin: minuteClosed };
}

export enum SupportStatus {
    open,
    closed,
    unknown,
}

export function getSupportHoursAndStatus(
    org?: Organization,
    partnerSupported = false,
    dayOfWeek = new Date().getDay(),
) {
    let openHour: number;
    let closeHour: number;
    let openMin = 0;
    let closeMin = 0;
    let daysOfWeek;
    let rawHourString;
    switch (dayOfWeek) {
        case 0:
            daysOfWeek = "Sunday: ";
            if (org && org.sundayHours) {
                rawHourString = org.sundayHours;
            } else if (!partnerSupported && hasInHouseSundaySupport) {
                openHour = inHouseSupportHours.sunday.open;
                closeHour = inHouseSupportHours.sunday.close;
            } else {
                return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
            }
            break;
        case 6:
            daysOfWeek = "Saturday: ";
            if (org && org.saturdayHours) {
                rawHourString = org.saturdayHours;
            } else if (!partnerSupported && hasInHouseSaturdaySupport) {
                openHour = inHouseSupportHours.saturday.open;
                closeHour = inHouseSupportHours.saturday.close;
            } else {
                return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
            }
            break;
        default:
            daysOfWeek = "Monday – Friday: ";
            if (org && org.weekdayHours) {
                rawHourString = org.weekdayHours;
            } else if (!partnerSupported) {
                openHour = inHouseSupportHours.weekday.open;
                closeHour = inHouseSupportHours.weekday.close;
            } else {
                return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
            }
    }

    const supportTimezone =
        org && org.timezoneId
            ? org.timezoneId
            : moment.tz(new Date(), inHouseSupportTimezoneId).format("z");
    if (rawHourString) {
        if (rawHourString.toLowerCase().trim() === "closed") {
            return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
        }
        const parsedHourString = parseSupportHours(rawHourString);
        if (!parsedHourString) {
            return {
                hours: daysOfWeek + rawHourString.toLowerCase(),
                status: SupportStatus.unknown,
            };
        }
        openHour = parsedHourString.open;
        openMin = parsedHourString.openMin;
        closeHour = parsedHourString.close;
        closeMin = parsedHourString.closeMin;
    }
    if (!Is.defined(openHour) || !closeHour) {
        return {
            hours: "Contact Everlaw support or your Everlaw partner for support hours",
            status: SupportStatus.unknown,
        };
    }

    if (closeHour - openHour >= 24) {
        // Open 24 hours or greater than 24 hours, so just say 24 hour coverage
        return {
            hours: daysOfWeek + "24 hour coverage",
            status: SupportStatus.open,
        };
    }
    const supportHours =
        formatHour(openHour, openMin) + " – " + formatHour(closeHour, closeMin) + " ";

    return {
        hours: daysOfWeek + supportHours + supportTimezone,
        status: checkIfInhouseSupportOpen(openHour, openMin, closeHour, closeMin, supportTimezone),
    };
}

export function checkIfInhouseSupportOpen(
    hourOpen: number,
    minOpen: number,
    hourClosed: number,
    minClosed: number,
    timezoneId: string,
): SupportStatus {
    // If the org's support timezone isn't a legitimate timezone id,
    // guess at the user's time zone instead
    const timezone = !!moment.tz.zone(timezoneId) ? timezoneId : moment.tz.guess();

    let hourOfDay = parseInt(moment().tz(timezone).format("HH"));
    const currentMinute = parseInt(moment().tz(timezone).format("mm"));
    if (hourClosed < hourOpen || (hourClosed === hourOpen && minOpen >= minClosed)) {
        hourClosed += 24;
    }
    if (hourOfDay < hourOpen) {
        hourOfDay += 24;
    }
    const isOpen =
        (hourOfDay > hourOpen || (hourOfDay === hourOpen && currentMinute >= minOpen))
        && (hourOfDay < hourClosed || (hourOfDay === hourClosed && currentMinute <= minClosed));
    return isOpen ? SupportStatus.open : SupportStatus.closed;
}

export function getSupportPhone(partner?: Partner) {
    return (partner && partner.phone) || supportPhone;
}

export function getSupportEmail(partner?: Partner) {
    return (partner && partner.email) || supportEmail;
}

export function getSupportBy(partner?: Partner) {
    return partner && partner.organization && partner.organization.name
        ? "Supported by: " + partner.organization.name
        : inHouseSupportMessage;
}

function helpColumn(...sections: Node[]) {
    return Dom.div(
        {
            style: {
                display: "flex",
                flexDirection: "column",
            },
        },
        ...sections,
    );
}

function helpSection(title: string, ...entries: string[]) {
    const entryNodes = entries.map((entry: string) => {
        return Dom.div({}, entry);
    });
    return Dom.div(
        {
            style: {
                marginBottom: "24px",
            },
        },
        Dom.h6(
            {
                style: {
                    margin: "8px 0 8px",
                    fontSize: "0.9rem",
                },
            },
            title,
        ),
        ...entryNodes,
    );
}

export function advancedContentSearchHelpIcon(): IconButton {
    const lazyPanel = getHelpPanel(
        getAdvancedContentSearchHelp(),
        720,
        516,
        "Content search types and examples",
    );

    return new IconButton({
        iconClass: "info-circle-20",
        // The default Input.tap does not correctly capture all clicks, while Input.press does.
        suppressDojoEvent: "press",
        tooltip: "View content search types and examples",
        onClick: () => {
            lazyPanel().toggle();
        },
    });
}

function getAdvancedContentSearchHelp(): HTMLElement {
    return Dom.div(
        Dom.div({ class: "focus-div", tabIndex: "0" }),
        Dom.div(
            { class: "content-search-help-panel-2-cols" },
            helpColumn(
                helpSection(
                    "Boolean",
                    '"first snow" AND "time to ski"',
                    '"not today" OR raincheck',
                    '"not today" raincheck',
                ),
                helpSection(
                    "Wildcard",
                    "Pa?t → Past, Part, Pant",
                    "Pa*t → Pat, Parent, Patient, Patent",
                    "/.*end/ → Lend, Blend, Ascend",
                ),
                helpSection(
                    "Proximity",
                    '"Werewolf village"~10',
                    '"("Escape from")("Werewolf village")"~20',
                ),
                helpSection("Proximity with word order", '"Werewolf village"~~30'),
            ),
            helpColumn(
                helpSection("Fuzzy", "Pass~ → Past, Passes, Mass, Part"),
                helpSection(
                    "Regular expressions",
                    "/[1-5][0-9]/ → Numbers 10–59",
                    "/ma+d/ → mad, maad, maaad, etc.",
                ),
                helpSection(
                    "Smart expressions",
                    "<ssn>",
                    "<phone>",
                    "<email>",
                    "<ein> → Employee Identification number",
                    "<iban> → International Bank Account Number",
                    "<credit-card> → Credit card numbers",
                    "<phone=805*> → 805-444-9876, (805) 555-1234, etc.",
                ),
            ),
        ),
        learnMoreLink(
            "https://support.everlaw.com/hc/en-us/articles/210132673-Advanced-Content-Searches-Wildcard-Proximity-Fuzzy-Regular-Expression-",
        ),
    );
}

export function getMessagesSearchHelp() {
    return Dom.div(
        { style: { float: "left" } },
        Dom.div({ class: "focus-div", tabIndex: "0" }),
        helpColumn(
            helpSection("Basic", "zebra lion tiger"),
            helpSection(
                "Content",
                "text:aardvark",
                "text:shar*",
                "subject:(spotted giraffe)",
                "attachment:(Important binder)",
            ),
            helpSection("Users", "from:reviewer~5", "to:Alice"),
            learnMoreLink(
                "https://support.everlaw.com/hc/en-us/articles/205083209-Message-Center-Overview#h_01EZD82ZA75JQXKZ8TC6D5BRAC",
            ),
        ),
    );
}

function learnMoreLink(href: string) {
    return Dom.a(
        {
            href: href,
            target: "_blank",
            rel: "noopener noreferrer",
            style: {
                color: ColorTokens.TEXT_LINK,
            },
        },
        "Learn more",
    );
}

export function getHelpPanel(
    content: HTMLElement,
    minWidth: number,
    minHeight: number,
    title: string,
): () => FloatingPanel {
    return Util.lazy(() => {
        return new FloatingPanel({
            title: title,
            class: "dialog-created-floating-panel",
            content: content,
            minWidth: minWidth,
            minHeight: minHeight,
            resizable: false,
            listenForCloseEvents: true,
            onToggle: (open, fromX) => {
                open
                    && setTimeout(() => {
                        Dom.firstChild(content, "focus-div").focus();
                    }, 1000);
            },
        });
    });
}

export function getOverlappingBatesHelp(text = "Learn more about overlapping Bates setting") {
    return Dom.a(
        {
            href: "https://support.everlaw.com/hc/en-us/articles/360021902991#h_01G2DF4DDPNMVVSSCDNHQ8S1PK",
            target: "_blank",
            rel: "noopener noreferrer",
            class: "text-action action",
        },
        text,
    );
}
